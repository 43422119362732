<template>
  <!-- 
  ----
  Groups fixed top 
  ----
  -->
  <div
    ref="fixedTopElement"
    v-if="groups.fixedTop.length !== 0"
    :id="`fixed-top-${breadcumb.id}`"
    class="xone-fixed-top"
  >
    <Container
      v-for="fixed in groups.fixedTop"
      :key="`fixed-top-${fixed.attributes.name}`"
      :xoneDataObject="xoneDataObject"
      :control="fixed"
      :containerWidth="containerWidth"
      :containerHeight="containerHeight"
    ></Container>
  </div>

  <!-- 
  ----
  Groups tabs header buttons
  ----
  -->
  <Groups v-if="!attributes.noTab" :groups="groups.tabs"></Groups>

  <div
    class="xone-groups-content-box"
    :style="{
      width: `${containerWidth}px`,
      position: groups.drawerLeft.length !== 0 ? 'absolute' : '',
      maxHeight: contentElementHeight && `${contentElementHeight}px`,
    }"
  >
    <!-- 
    ----
    Groups tabs content
    ----
    -->
    <div
      ref="contentElement"
      class="xone-group"
      :style="{
        marginLeft: `${
          drawers.showLeft
            ? drawers.leftWidth
            : drawers.showRight
            ? -drawers.rightWidth
            : 0
        }px`,
        maxHeight: contentElementHeight && `${contentElementHeight}px`,
      }"
    >
      <Container
        :class="('tabcontent', groupAnimation)"
        v-for="group in groups.tabs"
        :key="`group-${group.attributes.name}`"
        :xoneDataObject="xoneDataObject"
        :control="group"
        :containerWidth="containerWidth"
        :containerHeight="contentElementHeight"
      ></Container>
    </div>

    <!-- 
    ----
    Drawers
    ----
    -->
    <!-- Close Drawers -->
    <div
      class="xone-close-drawer"
      :class="[drawers.showLeft || drawers.showRight ? 'fade-in' : 'fade-out']"
      v-if="drawers.showLeft || drawers.showRight"
      @click="drawers.closeDrawers()"
    ></div>
    <!-- Drawer Left -->
    <Container
      v-if="groups.drawerLeft.length !== 0"
      :id="`xone-sidenav-left-${breadcumb.id}`"
      class="xone-sidenav-left"
      :xoneDataObject="xoneDataObject"
      :control="groups.drawerLeft[0]"
      :containerWidth="containerWidth"
      :containerHeight="containerHeight"
      :style="{
        opacity: drawers.showLeft ? 1 : 0,
        marginLeft: `${drawers.showLeft ? 0 : -drawers.leftWidth}px`,
        pointerEvents: drawers.showLeft ? 'all' : 'none',
      }"
    ></Container>
    <!-- Drawer Right -->
    <Container
      v-if="groups.drawerRight.length !== 0"
      :id="`xone-sidenav-right-${breadcumb.id}`"
      class="xone-sidenav-right"
      :xoneDataObject="xoneDataObject"
      :control="groups.drawerRight[0]"
      :containerWidth="containerWidth"
      :containerHeight="containerHeight"
      :style="{
        opacity: drawers.showRight ? 1 : 0,
        marginRight: `${drawers.showRight ? 0 : -drawers.rightWidth}px`,
        pointerEvents: drawers.showRight ? 'all' : 'none',
      }"
    ></Container>
  </div>

  <!--
  ----
  Groups fixed bottom
  ----
  -->
  <div
    ref="fixedBottomElement"
    :id="`fixed-bottom-${breadcumb.id}`"
    class="xone-fixed-bottom"
  >
    <Container
      v-for="fixed in groups.fixedBottom"
      :key="`fixed-bottom-${fixed.attributes.name}`"
      :xoneDataObject="xoneDataObject"
      :control="fixed"
      :containerWidth="containerWidth"
      :containerHeight="containerHeight"
    ></Container>
  </div>
  <!-- <div style="position: absolute; bottom: 0; background: white; z-index: 999">
    {{ groups.fixedTop }}
  </div> -->
</template>

<script>
// vue
import {
  computed,
  inject,
  onBeforeMount,
  onMounted,
  onUnmounted,
  provide,
  reactive,
  ref,
  Ref,
  watchEffect,
  ComputedRef,
  nextTick,
  watch,
} from "vue";
// components
import Groups from "@/components/Groups";
// composables
import { SwipeHandler } from "../composables/SwipeHandler";
import AppDataHandler from "../composables/AppDataHandler";
import {
  getCollGroups,
  showGroup,
  slideGroup,
  getDrawersWidth,
  watchContentElementSize,
  CollGroups,
} from "../composables/helperFunctions/GroupsHelper";
import {
  xoneAttributesHandler,
  ContainerAttributes,
} from "../composables/XoneAttributesHandler";
import XoneUI from "../composables/XoneUI";
import { XoneDataObject } from "../composables/appData/core/XoneDataObject";
import { XoneView } from "../composables/XoneViewsHandler";
import xoneUI from "../composables/XoneUI";

export default {
  props: {
    breadcumb: {
      type: Object,
      default: null,
    },
  },
  components: {
    Groups,
  },
  setup(props) {
    /**
     * Get XoneDataObject
     * @type {XoneDataObject}
     */
    const xoneDataObject = AppDataHandler.getDataObject(props.breadcumb.id);
    xoneDataObject["XoneHashId"] = props.breadcumb.id.toString(); // Asign hash id width breadcumbId to XoneDataObject

    /**
     * Last breadcumb in stack
     * @type {ComputedRef<string>}
     */
    const lastBreadcumb = inject("lastBreadcumb");

    // Provide breadcumbId info to child components
    provide("breadcumbId", props.breadcumb.id);

    /**
     * Bind data model
     * @type {Ref<Object>}
     */
    const dataModel = ref(xoneDataObject.model);
    xoneDataObject.model = dataModel.value;

    /**
     * Layout model
     * @type {Ref<Object>}
     */
    const layout = ref();

    /**
     * Viewport size conditions sm / md / lg
     * @type {Ref<string>}
     */
    const sizeConditions = inject("sizeConditions");

    /**
     * orientation vertical / horizontal
     * @type {Ref<string>}
     */
    const orientation = inject("orientation");

    const getLayout = () => {
      layout.value = xoneDataObject.getLayout();
      xoneDataObject.setLayout(layout.value);
    };

    getLayout();

    watch(
      () => sizeConditions.value,
      () => getLayout()
    );

    watch(
      () => orientation.value,
      () => getLayout()
    );

    /**
     * Collection attributes
     * @type {ComputedRef<ContainerAttributes>}
     */
    const attributes = computed(() =>
      xoneAttributesHandler.getContainerAttributes(layout.value.attributes)
    );

    // Execute node before-edit
    onBeforeMount(() => {
      (async () => {
        try {
          await xoneDataObject.ExecuteNode("before-edit");
        } catch {}
      })();
    });

    // Execute node after-edit
    onMounted(() => {
      (async () => {
        try {
          await xoneDataObject.ExecuteNode("after-edit");
        } catch {}
      })();
    });

    //
    // Manage coll groups

    /**
     * Groups
     * @type {ComputedRef<CollGroups>}
     */
    const groups = computed(() => getCollGroups(layout.value?.controls));

    /**
     * Active Group
     * @type {Ref<string>}
     */
    const activeGroup = ref(
      groups.value.tabs.length !== 0 && groups.value.tabs[0].attributes.id
    );

    const changeGroup = (id) =>
      showGroup(id, groups.value, drawers, activeGroup, groupAnimation);

    /**
     * Window Size
     * @type {{containerWidth: Ref<number>, containerHeight: Ref<number>}}
     */
    const { containerWidth, containerHeight } = inject("containerSize");

    /**
     * Group tab content element
     * @type {Ref<number>}
     */
    const contentElementHeight = ref(containerWidth.value);

    const fixedTopElement = ref();
    const fixedBottomElement = ref();

    // Watch contents element height changes
    watchContentElementSize(
      contentElementHeight,
      containerHeight,
      containerWidth,
      fixedTopElement,
      fixedBottomElement,
      props.breadcumb.id
    );

    /**
     * xone View
     * @type {XoneView}
     */
    const xoneView = new XoneView(xoneDataObject);
    provide("xoneView", xoneView);

    // Current XoneDataObject focused
    watchEffect(() => {
      if (props.breadcumb.id === lastBreadcumb.value?.id) {
        // Send changeGroup to XoneUI showGroup method
        XoneUI.setShowGroupCallback(changeGroup);
        // Send refresh items
        xoneUI.setXoneDataObject(xoneDataObject);
      }
    });

    // Provide groupHandler to child components
    provide("groupHandler", {
      // active group
      activeGroup: activeGroup,
      // change group callback function
      changeGroup,
    });

    /**
     * Group transition animation
     * @type {Ref<string>}
     */
    const groupAnimation = ref("");

    /**
     * contentElement
     * @type {Ref<HTMLElement>}
     */
    const contentElement = ref();
    /**
     * Change current group by swipe
     * @type {SwipeHandler}
     */
    const swipeHandler = new SwipeHandler(
      // swipe group callback function
      (step) => slideGroup(step, groups.value, activeGroup, groupAnimation)
    );
    // Initialize group swipe handler
    onMounted(() => swipeHandler.init(contentElement.value));
    // clear group swipe handler
    onUnmounted(() => swipeHandler?.clear());

    /**
     * drawers
     */
    const drawers = reactive({
      leftWidth: 0,
      rightWidth: 0,
      showLeft: false,
      showRight: false,
      closeDrawers: () => {
        drawers.showLeft = false;
        drawers.showRight = false;
      },
      currentWindowWidth: containerWidth.value,
    });

    // Get drawers width
    nextTick(() =>
      getDrawersWidth(drawers, containerWidth, props.breadcumb.id)
    );

    // Provide object info
    provide("objectInfo", { isContents: false });

    return {
      dataModel,
      attributes,
      groups,
      drawers,
      groupAnimation,
      containerWidth,
      containerHeight,
      contentElementHeight,
      xoneDataObject,
      fixedTopElement,
      fixedBottomElement,
      contentElement,
    };
  },
};
</script>

<style scoped>
.xone-fixed-top {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2), 0 0px 10px rgba(0, 0, 0, 0.24);
}

.xone-groups-content-box {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
}

.xone-close-drawer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  pointer-events: all;
}

.fade-in {
  animation: fadeIn03 0.3s;
  opacity: 0.3;
}

.fade-out {
  animation: fadeOut03 0.3s;
  opacity: 0;
}

.xone-group {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 1;
  overflow: hidden;
  height: auto;
  transition: opacity 0s, margin-left 0.3s, margin-right 0.3s;
}

.xone-sidenav-left {
  position: absolute;
  flex-shrink: 0;
  top: auto;
  left: 0;
  width: auto;
  pointer-events: all;
  opacity: 0;
  transition: all 0.3s;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2), 0 0px 10px rgba(0, 0, 0, 0.24);
}

.xone-sidenav-right {
  position: absolute;
  flex-shrink: 0;
  top: auto;
  right: 0;
  width: auto;
  pointer-events: all;
  opacity: 0;
  transition: all 0.3s;
}

/* Change group animations */
.slide-left {
  animation: slideLeft 0.2s; /* ease-out */
}

.slide-right {
  animation: slideRight 0.2s; /*ease-out*/
}
</style>