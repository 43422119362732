<template>
  <div class="tab">
    <button
      :class="[
        'tablinks',
        activeGroup === group.attributes.id && 'activeGroup',
      ]"
      v-for="group in groups"
      :key="`grp-btn-${group.attributes.id}`"
      @click="changeGroup(group.attributes.id)"
    >
      {{ group.attributes.name }}
    </button>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  name: "Group",
  props: {
    groups: {
      type: Array,
      default: new Array(),
    },
  },
  setup() {
    const { activeGroup, changeGroup } = inject("groupHandler");
    return { activeGroup, changeGroup };
  },
};
</script>

<style scoped>
/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: white;
  display: flex;
  overflow-x: auto;
  flex-shrink: 0;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 7px 8px;
  transition: 0.3s;
  font-size: 17px;
  flex-shrink: 0;
  box-sizing: border-box;
}

/* Change background color of buttons on hover */
.tab button:hover {
  color: black;
  border-bottom: 1px solid black;
}

/* Create an activeGroup/current tablink class */
.tab button.activeGroup {
  color: #3273dc;
  border-bottom: 1px solid #3273dc;
}
</style>