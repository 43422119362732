<template>
  <template v-for="breadcumb of breadcumbs" :key="breadcumb.id">
    <div class="xone-dataobject" v-show="breadcumb === lastBreadcumb">
      <Coll :breadcumb="breadcumb"></Coll>
    </div>
  </template>
  <!-- <div style="position: absolute; background: white; top: 0">
    <p>{{ lastBreadcumb }}</p>
    {{ breadcumbs }}
  </div> -->
</template>

<script>
import Coll from "@/components/Coll";
import { computed, provide, watch, watchEffect } from "vue";
import AppDataHandler from "../composables/AppDataHandler";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    Coll,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    // breadcumbs -> Object Stack
    const breadcumbs = computed(() => AppDataHandler.getbreadcumbs());

    const lastBreadcumb = computed(
      () =>
        breadcumbs.value.length !== 0 &&
        breadcumbs.value[breadcumbs.value.length - 1]
    );
    // Provide lastBreadcumb to child components
    provide("lastBreadcumb", lastBreadcumb);

    const doLogin = () => {
      AppDataHandler.clearbreadcumbs();
      // Create login XoneDataObject
      AppDataHandler.addNewXoneDataObject(
        AppDataHandler.getAppData().getLoginCollectionName(),
        "Login"
      );
    };

    // Go to Login
    // watchEffect(() => {
    // route.name === "Login" && breadcumbs.value.length === 0 && doLogin();
    // });

    // Change route depending on the last breadcumb
    watch(
      () => lastBreadcumb.value.id,
      (newValue) => {
        if (!lastBreadcumb.value)
          setTimeout(() => {
            if (!lastBreadcumb.value) doLogin();
          }, 500);
        const type = lastBreadcumb.value.type;
        if (type !== "Login")
          router.push({
            name: "Coll",
            query: { id: lastBreadcumb.value.id },
          });
      }
    );

    // Control history route modifications
    watch(
      () => route.query,
      (newValue, oldValue) => {
        if (lastBreadcumb.value.type === "Coll")
          AppDataHandler.clearbreadcumbFrom(newValue.id);

        if (route.name === "Login" && lastBreadcumb.value.type !== "Login")
          doLogin();
      }
    );

    return { breadcumbs, lastBreadcumb };
  },
};
</script>
<style scoped>
.xone-dataobject {
  position: relative;
  flex-grow: 1;
  animation: loadEffect 0.3s;
  display: flex;
  flex-direction: column;
}

@keyframes loadEffect {
  from {
    /* transform: translate(0, 100px); */
    opacity: 0;
  }
  to {
    /* transform: translate(0, 0); */
    opacity: 1;
  }
}
</style>